import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Newsletter
// const Newsletter = React.lazy(() => import("./components/layouts/Newsletter"));

// Pages
// const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const About = React.lazy(() => import("./components/pages/About"));
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogmasonary = React.lazy(() => import("./components/pages/Blogmasonary"));
const Postsingle = React.lazy(() => import("./components/pages/Postsingle"));
const Cart = React.lazy(() => import("./components/pages/Cart"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Legal = React.lazy(() => import("./components/pages/PrivacyandPolicy"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Wishlist = React.lazy(() => import("./components/pages/Wishlist"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Shopone = React.lazy(() => import("./components/pages/Shopone"));
const Shoptwo = React.lazy(() => import("./components/pages/Shoptwo"));
const Shopthree = React.lazy(() => import("./components/pages/Shopthree"));
const Shopfour = React.lazy(() => import("./components/pages/Shopfour"));
const Productsingle = React.lazy(() => import("./components/pages/Productsingle"));
const Productsingletwo = React.lazy(() => import("./components/pages/Productsingletwo"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const MyAccount = React.lazy(() => import("./components/pages/myAccount"));
const ChangePassword = React.lazy(() => import("./components/pages/ChangePassword"));
const MyOrders = React.lazy(() => import("./components/pages/MyOrders"));
const ForgotPassword = React.lazy(() => import("./components/pages/ForgotPassword"));
const PopularCollection = React.lazy(() => import("./components/pages/PopularList"));
const PopularSubcategoryCollection = React.lazy(() => import("./components/pages/PopularSubcategoryList"));
const RefundPolicy = React.lazy(() => import("./components/pages/RefundPolicy"));
const ShippingPolicy = React.lazy(() => import("./components/pages/ShippingPolicy"));
const TermsandConditions = React.lazy(() => import("./components/pages/TermsandConditions"));

function App({selectedCurrency,selectImage}) {
  return (
    <Router basename={'/'}>
      <Suspense fallback={<div></div>}>
        <Preloader />
        {/* <Newsletter /> */}
        <Switch>
        <Route
            exact
            path="/"
            render={(props) => <Hometwo {...props} selectedCurrency={selectedCurrency} selectImage={selectImage} />}
          />
          {/* <Route exact path="/" component={Home} />
          <Route path="/home-v2" component={Hometwo} /> */}
          <Route path="/home-v3" component={Homethree} />
          <Route path="/about" component={About} />
          <Route path="/blog-grid" component={Bloggrid} />
          <Route path="/blog-list" component={Bloglist} />
          <Route path="/blog-masonry" component={Blogmasonary} />
          <Route path="/post-single/:id" component={Postsingle} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />
          
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/wishlist" component={Wishlist} />
          <Route path="/error" component={Error} />
          <Route path="/shop-v1" component={Shopone} />
          <Route path="/shop-v2" component={Shoptwo} />
          <Route path="/shop-v3" component={Shopthree} />
          <Route path="/shop-v4" component={Shopfour} />
          <Route path="/product-single/:id" component={Productsingle} />
          <Route path="/product-single-v2/:id" component={Productsingletwo} />
          <Route path="/contact" component={Contact} />
          <Route path="/myAccount" component={MyAccount} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/myOrders" component={MyOrders} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/popular-collection" component={PopularCollection} />
          <Route path="/privacy-and-policy" component={Legal} />
          <Route path="/Refund-Policy" component={RefundPolicy} />
          <Route path="/Shipping-Policy" component={ShippingPolicy} />
          <Route path="/Terms-and-Conditions" component={TermsandConditions} />
          <Route path="/popular-subcategory-collection" component={PopularSubcategoryCollection} />
          
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
