// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

// // Css
// import '../node_modules/animate.css/animate.css';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/slick-carousel/slick/slick.css';
// import '../node_modules/slick-carousel/slick/slick-theme.css';
// import '../node_modules/magnific-popup/dist/magnific-popup.css';
// import './assets/fonts/flaticon/flaticon.css';
// import './assets/fonts/font-awesome/css/all.min.css';
// import './assets/css/style.css';
// import './index.css';

// ReactDOM.render(
//   <BrowserRouter basename={'/templatemonster/react/letam'}>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('letam')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// Css
import '../node_modules/animate.css/animate.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/magnific-popup/dist/magnific-popup.css';
import './assets/fonts/flaticon/flaticon.css';
import './assets/fonts/font-awesome/css/all.min.css';
import './assets/css/style.css';
import './index.css';

function Index() {
  // Define state to manage selected currency
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectImage, setSelectImage] = useState("");

  // Fetch user's location using IP geolocation API
  useEffect(() => {
    fetch('https://ipapi.co/json')
      .then(response => response.json())
      .then(data => {
        // Check if the user is in India
        if (data.country_code === 'IN') {
          setSelectedCurrency('INR');
          setSelectImage("/assets/img/India_flag.png");
          localStorage.setItem('selectedCurrency', 'INR');
        } else {
          setSelectedCurrency('USD');
          setSelectImage("/assets/img/united-states_flag.png");
          localStorage.setItem('selectedCurrency', 'USD');
        }
      })
      .catch(error => {
        console.error('Error fetching IP geolocation:', error);
      });
  }, []);

  // useEffect(() => {
  //   // Update selectImage based on selectedCurrency and currencyData
  //   if (currency === 'USD') {
  //     setSelectedCurrency("USD");
  //     setSelectImage("/assets/img/united-states_flag.png");
  //   } else {
  //     setSelectedCurrency("INR");
  //     setSelectImage("/assets/img/India_flag.png");
  //   }
  // }, []);

  return (
    <BrowserRouter basename={'/'}>
      <App selectedCurrency={selectedCurrency} selectImage={selectImage} />
    </BrowserRouter>
  );
}

ReactDOM.render(
  <Index />,
  document.getElementById('letam')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
